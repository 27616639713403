import React from 'react';

import { Link } from "gatsby";
import EmailPopup from "../components/email-popup";
import Navbar from "../components/navbar";
import Footer from '../components/footer';
import Seo from '../components/seo';
import RegistrationForm from '../components/registration-form';


const Offer = () => {

    return (
        <div id="main_content">
            <Seo title='Promptalkotás alapjai' />
            <Navbar dark={true} />
            <EmailPopup />

            <section className="page-banner">
                <div className="container">
                    <div className="page-title-wrapper">
                        <h1 className="page-title">Promptalkotás alapjai</h1>

                        <ul className="bradcurmed">
                            <li>
                                <Link to="/">
                                    Home
                                </Link>
                            </li>
                            <li>Promptalkotás alapjai</li>
                        </ul>
                    </div>
                </div>
                <svg
                    className="circle"
                    data-parallax='{"x" : -200}'
                    xmlns="http://www.w3.org/2000/svg"
                    width="950px"
                    height="950px"
                >
                    <path
                        fillRule="evenodd"
                        stroke="rgb(250, 112, 112)"
                        strokeWidth="100px"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        opacity="0.051"
                        fill="none"
                        d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
                    />
                </svg>
                <ul className="animate-ball">
                    <li className="ball"></li>
                    <li className="ball"></li>
                    <li className="ball"></li>
                    <li className="ball"></li>
                    <li className="ball"></li>
                </ul>
            </section>

            <section className="hero-section">
                <div className="hero-container container">

                    <div className="hero-viewport">
                        <div className="hero-image">
                            <img src="images/front/prompt_alkotas_hero2.jpg" alt="prompt-alkotas-alapjai" />
                        </div>
                        <div className="overlay ">
                            <div className="col-xl-7 col-lg-9 col-md-12">
                                <div className="action-content text-left wow pixFadeUp">
                                    <h2 className="title">Válj a ChatGPT <br /> mesterévé <span style={{ color: "#FA7070" }}>a Skillgoval!</span>
                                    </h2>

                                    <p>Fedezd fel a ChatGPT nyújtotta lehetőségeket és <span style={{ color: "#FA7070" }}>sokszorozd meg</span> vállalkozásod hatékonyságát!
                                    </p>

                                    <a href="/prompt-alkotas-alapjai-chatgpt/#PromptContactForm" className="pix-btn wow pixFadeUp">
                                        Érdekel
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="prompt-section">
                <div className="container">
                    <div className="col-auto">
                        <div className="content text-center wow pixFadeUp">
                            <h2 className="title">Mit tanítunk meg Neked?
                            </h2>
                            <hr />
                            <p style={{ color: "#FA7070" }}>
                                Ez a tananyag arra összpontosít, hogy az első lépéseket megtegyük az MI-t aktívan használók világában és elkezdjük az
                                ismerkedést a mesterséges intelligenciát irányító promptokkal (parancsokkal). A kurzusból elsajátíthatod:
                            </p>
                            <div className="list-align">
                                <ul className="what-teach-list text-left">
                                    <li><span>Az <strong>alapfogalmakat</strong>, hogy ne csak használd, hanem <strong>értsd is az eszközöket</strong>.</span></li>
                                    <li><span><strong>Hogyan és mire tudod használni</strong> a ChatGPT-t a gyakorlatban.</span></li>
                                    <li><span><strong>A promptalkotás alapjait és a promptok szerkezetét</strong></span></li>
                                    <li><span><strong>A hatékony prompt-alkotás kezdő és haladó stratégiáit</strong></span></li>
                                    <li><span><strong>BÓNUSZ</strong>ként pedig megismertetünk a ChatGPT korlátaival is.</span></li>
                                </ul>
                            </div>

                            <a href="/prompt-alkotas-alapjai-chatgpt/#PromptContactForm" className="pix-btn wow pixFadeUp">
                                Ez kell nekem!
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="prompt-section" style={{ background: "#FDF8F8", paddingBottom: "5rem" }}>
                <div className="container">
                    <div className="content wow pixFadeUp">
                        <h2 className="title text-center">Kik vagyunk mi?
                        </h2>
                        <hr />
                        <div className="row who-we-are-row">
                            <img
                                src="images/front/kik_vagyunk_mi2.jpg"
                                className="image-left wow pixFadeLeft"
                                alt="kik-vagyunk-mi"
                            />
                            <div className="right-text-block">

                                <div className="wow pixFadeUp" data-wow-delay="0.5s">
                                    <p>
                                        A Neting Informatika Kft. tananyagfejlesztési tapasztalata több mint 20 éves múltra tekint vissza.
                                        <br />
                                        <br />
                                        Több száz sikeres hazai és nemzetközi projekt, valamint számos elismerés bizonyítja azt az elhivatottságot,
                                        tapasztalatot, szakértelmet és ügyfélközpontúságot, amit minden meglévő és leendő ügyfelünknek garantálunk.
                                        <br />
                                        <br />
                                        Ezt a tartalmat azért hoztuk létre, mert számos ismerősünk, barátunk és üzleti partnerünk érdeklődött
                                        az MI promptok iránt. Szeretnénk ezzel a tartalommal segítséget nyújtani nekik.
                                        <br />
                                        <br />
                                        Bátorítunk mindenkit, hogy kezdje el alkalmazni az MI-t és használja fel annak eredményeit az életében!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section >

            <section className="prompt-contact-section" id="PromptContactForm" style={{ background: "#252B42", paddingBottom: "5rem" }}>
                <div className="container" style={{maxWidth: "1570px"}}>
                    <div className="row">

                        <div className="text-left col-xl-5 col-lg-5 col-md-12">
                            <div className="action-content text-left wow pixFadeUp">
                                <h2 className="title">Válj a ChatGPT <br /> mesterévé <span style={{ color: "#FA7070" }}>a Skillgoval!</span>
                                </h2>
                                <hr />

                                <p>Végezd el a tananyagot és sokszorozd meg vállalkozásod hatékonyságát!
                                </p>
                            </div>
                        </div>

                        <div className="prompt-contact col-xl-6 col-lg-6 col-md-12">
                            <div className="prompt-form-wrapper"> 
                                <RegistrationForm usedClassName="" dataForm="hmuAeZ"/>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />
        </div >

    )
}

export default Offer;
